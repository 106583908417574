import { Aptos, AptosConfig } from '@aptos-labs/ts-sdk'
import { SUPPORTED_POOLS } from './pool.ts'

export const ENV = import.meta.env.VITE_ENV
export const APP_VERSION = 19 // Pump version to purge state.

export const AGGREGATOR_URL = import.meta.env.VITE_AGGREGATOR_URL
export const LIMIT_ORDER_URL = import.meta.env.VITE_LIMIT_ORDER_URL

export const AGGREGATOR_API_KEY = import.meta.env.VITE_AGGREGATOR_API_KEY

export const MOVEMENT_RPC_URL = 'https://aptos.testnet.porto.movementlabs.xyz/v1'
export const AMM_URL = 'http://localhost:8080'
export const AMM_CONTRACT = '0x1202cd1f7634a28a1c0defc66b441e852c6db3128dd675a519c7169b16c3b1d0'

const aptosConfig = new AptosConfig({
  fullnode: MOVEMENT_RPC_URL,
  indexer: 'https://indexer.testnet.porto.movementnetwork.xyz/v1/graphql',
})
export const aptos = new Aptos(aptosConfig)

export const BIP_BASE = 10_000
export const DEFAULT_SLIPPAGE_BPS = 100

export const PRELOAD_IMAGES = [...Object.values(SUPPORTED_POOLS).map((p) => p?.logoUrl || '')].filter((i) => i)

export const MOVEMENT_CHAIN_ID = 27

export const REFRESH_BALANCE_INTERVAL = 10_000
export const NETWORK_STATUS_CHECKING_INTERVAL = 10_000

export const LIMIT_ORDER_CONTRACT = `0x3cb6ba92a0fcfcab24123daa74f5c28026e99aad57a76b42f0aca36bd215b19d`
export const TOKEN_CONTRACT = '0x8b354b93bbc846aa9b9419f41eb63327abfa3cd30520390d6dfc61d02fa093b8'
