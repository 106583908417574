import { getTokenAddress } from '@/utils/token.ts'
import { MoveStructId } from '@aptos-labs/ts-sdk'
import { useCallback, useMemo } from 'react'
import useSWR from 'swr'
import { aptos, REFRESH_BALANCE_INTERVAL, TOKEN_CONTRACT } from '../constants'
import { Asset } from '../constants/asset.ts'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { addTokensToFollow } from '../redux/slices/token'
import { updateBalance, WalletBalance } from '../redux/slices/wallet'
import useMovementWallet from './useMovementWallet.ts'
let indexerError = false

const fetchBalanceContractFn = async ({
  faToFetch,
  coins,
  address,
}: {
  faToFetch: string[]
  address: string | undefined
  coins: MoveStructId[]
}) => {
  const accountCoinsData: WalletBalance = {}
  const accountFaData: WalletBalance = {}

  if (address && (faToFetch.length || coins.length)) {
    const coinsArr = Array.from(new Set(coins))
    const coinsToFetch = coinsArr.concat(new Array(32 - coinsArr.length).fill(`${TOKEN_CONTRACT}::token::Null`))

    const result: {
      coin_balance: string
      coin_type: string
      fa_address: string
      fa_balance: string
    }[][] = await aptos.viewJson({
      payload: {
        function: `${TOKEN_CONTRACT}::token::get_token_balances`,
        functionArguments: [address, Array.from(new Set(faToFetch))],
        typeArguments: coinsToFetch,
      },
    })
    const balances = result[0] ?? []
    balances.forEach((item) => {
      if (item.coin_type && item.coin_balance !== '0') accountCoinsData[item.coin_type] = item.coin_balance
      if (item.fa_address && item.fa_balance !== '0') accountFaData[item.fa_address] = item.fa_balance
    })
  }

  return { accountCoinsData, accountFaData }
}

const fetchBalanceIndexer = async (address: string | undefined) => {
  const accountCoinsData: WalletBalance = {}
  const accountFaData: WalletBalance = {}
  if (address) {
    const dataPromise = aptos
      .getAccountCoinsData({
        accountAddress: address,
        options: {
          where: {
            owner_address: { _eq: address },
          },
          offset: 0,
          limit: 100,
        },
      })
      .catch((e) => {
        if (e.code === 'ERR_NETWORK') indexerError = true
        throw e
      })

    const timeoutPromise = new Promise<never>((_, reject) => setTimeout(() => reject('timeout'), 2000))

    const data = await Promise.race([dataPromise, timeoutPromise])

    data.forEach((item) => {
      if (!item.asset_type || !item.amount) return
      const key =
        item.asset_type === '0x000000000000000000000000000000000000000000000000000000000000000a'
          ? '0xa'
          : item.asset_type

      if (item.token_standard === 'v2') accountFaData[key] = item.amount
      else accountCoinsData[key] = item.amount
    })
  }
  return { accountCoinsData, accountFaData }
}

export default function useRefreshBalanceFn() {
  const dispatch = useAppDispatch()
  const { account } = useMovementWallet()
  const whiteListTokens = useAppSelector((state) => state.token.followingTokenData)

  const fn = useCallback(async () => {
    if (!account) return
    try {
      let resp: { accountCoinsData: WalletBalance; accountFaData: WalletBalance }
      try {
        if (indexerError) throw new Error('indexerError')
        resp = await fetchBalanceIndexer(account?.address)
      } catch (error) {
        const fetchBalanceContract = async () => {
          const faToFetch = Object.values(whiteListTokens ?? {})
            .map((token) => token?.faAddress)
            .filter(Boolean) as string[]

          const coinsToFetch = Object.values(whiteListTokens ?? {})
            .map((token) => token?.coinType)
            .filter(Boolean) as MoveStructId[]

          return fetchBalanceContractFn({ address: account?.address, coins: coinsToFetch, faToFetch })
        }
        resp = await fetchBalanceContract()
      }
      const { accountCoinsData, accountFaData } = resp
      dispatch(updateBalance({ coinBalance: accountCoinsData, balance: accountFaData }))
      dispatch(addTokensToFollow(Object.keys({ ...accountCoinsData, ...accountFaData }).map((coinType) => coinType)))
    } catch (err) {
      console.error(err)
    }
  }, [account, dispatch, whiteListTokens])

  return fn
}

const calcBalance = (token: Asset | undefined, balance: WalletBalance = {}, coinBalance: WalletBalance = {}) => {
  return (BigInt(balance?.[getTokenAddress(token)] || 0) + BigInt(coinBalance?.[token?.coinType ?? ''] || 0)).toString()
}

export const useGetTokenBalance = () => {
  const { balance, coinBalance } = useAppSelector((state) => state.wallet)
  return useCallback((token: Asset | undefined) => calcBalance(token, balance, coinBalance), [balance, coinBalance])
}

export const useTokenBalance = (token: Asset | undefined) => {
  const balance = useGetTokenBalance()(token) // balance from store

  const { account } = useMovementWallet()
  const { data } = useSWR(
    {
      key: balance !== '0' ? null : ['balance', getTokenAddress(token)],
      address: account?.address,
      faToFetch: token?.faAddress ? [token.faAddress] : [],
      coins: (token?.coinType ? [token.coinType] : []) as MoveStructId[],
    },
    fetchBalanceContractFn,
    {
      refreshInterval: REFRESH_BALANCE_INTERVAL,
    },
  )

  return useMemo(() => {
    return balance !== '0' ? balance : calcBalance(token, data?.accountFaData, data?.accountCoinsData) // balance from contract
  }, [data, token, balance])
}
