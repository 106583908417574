import { calcTotalTokenBalance, sortBalanceFn, useTokensHasBalance } from '@/hooks/common/token/useTokenBalance.ts'
import { Icon } from '@iconify/react'
import { Button, Input, Spacer } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { CSSProperties, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VariableSizeList } from 'react-window'
import { useDebounceValue, useOnClickOutside, useWindowSize } from 'usehooks-ts'
import { Asset } from '../constants/asset.ts'
import useFullTokens from '../hooks/useFullTokens.ts'
import { useAppSelector } from '../redux/hooks'
import { PartialRecord } from '../types.ts'
import { Fraction } from '../utils/fraction.ts'
import MainMotion from './anim/MainMotion.tsx'
import BasicTokenInfo from './BasicTokenInfo.tsx'
import { CloseIcon, SearchIcon } from './Icons.tsx'
import { Body2, Subtitle1 } from './Typography.tsx'

export interface AssetWithBalance extends Asset {
  isFollowing: boolean
  faBalance?: Fraction
  faBalanceUsd?: Fraction
  coinBalanceUsd?: Fraction
  coinBalance?: Fraction
}

function TokenItem({
  index,
  data: { items, onSelectToken, onChangeHeight },
  style,
}: {
  index: number
  data: {
    items: AssetWithBalance[]
    onSelectToken: (id: Asset) => void
    onChangeHeight: (height: number, index: number) => void
  }
  style: CSSProperties
}) {
  const token = useMemo(() => {
    return items[index]!
  }, [items, index])

  const [height, setHeight] = useState(
    token.coinType && !calcTotalTokenBalance(token).totalBalanceUsd.isZero() ? itemSize : itemSizeSmall,
  )
  useEffect(() => {
    onChangeHeight(height, index)
  }, [height, index, onChangeHeight])

  return (
    <motion.div
      animate={{ height }}
      className={
        'm-0 flex h-fit w-full min-w-fit cursor-pointer items-center gap-2 rounded-none p-0 px-4 font-normal hover:bg-baseBlack' +
        ' ' +
        (token.isFollowing ? 'opacity-100' : 'opacity-50')
      }
      style={style}
    >
      <BasicTokenInfo
        onClick={() => onSelectToken(token)}
        token={token}
        onChangeHeight={(expand) => {
          setHeight(expand ? itemSize : itemSizeSmall)
        }}
      />
    </motion.div>
  )
}

const filterFn = (token: Asset, str: string) => {
  if (token.id.toLowerCase() === str.toLowerCase() || token.coinType?.toLowerCase() === str.toLowerCase()) return true
  if (token.symbol.toLowerCase().includes(str.toLowerCase())) return true
  return false
}

const itemSize = 80
const itemSizeSmall = 58

function TokenSelector({
  swapCardRef,
  onSelectToken,
  onBack,
}: {
  swapCardRef: RefObject<HTMLDivElement>
  onSelectToken: (id: Asset) => void
  onBack: () => void
}) {
  useOnClickOutside(swapCardRef, onBack)

  const { data: fullTokenData } = useFullTokens()
  const followingAssetData = useAppSelector((state) => state.token.followingTokenData)

  const tokensHasBalance = useTokensHasBalance()
  const followingTokenDataWithBalance = useMemo(() => {
    const res: PartialRecord<string, AssetWithBalance> = { ...tokensHasBalance }
    for (const assetAddress of Object.keys(followingAssetData)) {
      if (res[assetAddress]) continue
      const tokenData = followingAssetData[assetAddress]!
      if (tokenData.type === 'legacy') continue
      const newItem: AssetWithBalance = {
        ...tokenData,
        isFollowing: true,
      }
      res[assetAddress] = newItem
    }
    return res
  }, [followingAssetData, tokensHasBalance])

  const followingTokenDataWithBalanceList = useMemo(() => {
    const list = Object.values(followingTokenDataWithBalance) as AssetWithBalance[]
    return list.sort(sortBalanceFn)
  }, [followingTokenDataWithBalance])

  const [_searchValue, setSearchValue] = useState('')
  const [searchValue] = useDebounceValue(_searchValue, 100)

  const renderFollowingTokenList = useMemo(() => {
    const str = searchValue.trim()
    if (!str) return followingTokenDataWithBalanceList

    const res = followingTokenDataWithBalanceList.filter((token) => {
      return filterFn(token, str)
    })
    return res
  }, [followingTokenDataWithBalanceList, searchValue])
  const renderUnfollowingTokenList = useMemo(() => {
    if (!fullTokenData) return []

    const str = searchValue.trim()
    if (!str) return []

    const fullTokenDataList = Object.values(fullTokenData) as Asset[]
    const fullTokenList: AssetWithBalance[] = fullTokenDataList
      .filter((token) => {
        return filterFn(token, str)
      })
      .filter((token) => !renderFollowingTokenList.map((token) => token.id).includes(token.id))
      .map((token) => ({
        ...token,
        whitelisted: false,
        logoUrl: undefined,
        fractionalBalance: undefined,
        fractionalBalanceUsd: undefined,
        isFollowing: false,
      }))
      .sort(sortBalanceFn)
    return fullTokenList
  }, [fullTokenData, renderFollowingTokenList, searchValue])
  const renderTokenList = useMemo(
    () => [...renderFollowingTokenList, ...renderUnfollowingTokenList],
    [renderFollowingTokenList, renderUnfollowingTokenList],
  )
  const isEmpty = renderTokenList.length === 0

  const listRef = useRef<any>()
  const itemRefs = useRef<Record<number, number>>({})
  const onChangeHeight = useCallback((height: number, index: number) => {
    itemRefs.current = {
      ...itemRefs.current,
      [index]: height || itemSizeSmall,
    }
    listRef.current?.resetAfterIndex(index)
  }, [])

  const getItemSize = (index: number) => {
    return itemRefs.current[index] || itemSizeSmall
  }

  const itemData = useMemo(
    () => ({ items: renderTokenList, onSelectToken, onChangeHeight }),
    [renderTokenList, onSelectToken, onChangeHeight],
  )

  const { height: windowHeight } = useWindowSize()
  const listHeight = useMemo(() => Math.min(500, Math.round(windowHeight / 2 / itemSize) * itemSize), [windowHeight])

  return (
    <MainMotion>
      <div className="flex flex-col rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
        <div className="flex items-center justify-between ">
          <Button
            variant="light"
            className="m-0 h-fit w-fit min-w-fit gap-0 p-0 data-[hover]:bg-transparent"
            disableAnimation
            disableRipple
            onPress={onBack}
          >
            <Icon icon="mdi:chevron-left" color="#8B8D91" fontSize={24} />
          </Button>
          <Subtitle1 className="text-baseGrey">Select an asset</Subtitle1>
          <Button
            variant="light"
            className="invisible m-0 h-fit w-fit min-w-fit gap-0 p-0 data-[hover]:bg-transparent"
            disableAnimation
            disableRipple
            onPress={onBack}
          >
            <Icon icon="mdi:chevron-left" color="#8B8D91" fontSize={24} />
          </Button>
        </div>

        <Spacer y={4} />

        <Input
          type="text"
          placeholder="Search by token symbol or address"
          labelPlacement="outside"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          className="input-modal-select-token"
          startContent={<SearchIcon size={20} color="#8B8D91" className="min-w-[20px]" />}
          endContent={
            searchValue ? (
              <Button
                isIconOnly
                className="m-0 h-fit w-fit min-w-fit border-transparent bg-transparent p-0"
                disableRipple
                onPress={() => setSearchValue('')}
              >
                <CloseIcon size={16} color="#8B8D91" />
              </Button>
            ) : null
          }
          value={_searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
        />

        <Spacer y={2} />

        {renderTokenList && (
          <div className="relative -mx-3 border-t-1 border-t-borderGrey">
            <VariableSizeList
              ref={listRef}
              height={listHeight}
              itemCount={renderTokenList.length}
              itemSize={getItemSize}
              width="100%"
              itemData={itemData}
            >
              {TokenItem}
            </VariableSizeList>
            {isEmpty && (
              <Body2 className="absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2 text-baseGrey">
                No Token Found
              </Body2>
            )}
          </div>
        )}
      </div>
    </MainMotion>
  )
}

const MemorizedModalSelectToken = memo(TokenSelector)
export default MemorizedModalSelectToken
