import apiClient from '@/constants/axios.ts'
import { InputEntryFunctionData } from '@aptos-labs/ts-sdk'
import invariant from 'invariant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { AGGREGATOR_URL } from '../constants'
import { Asset } from '../constants/asset.ts'
import { SUPPORTED_POOLS } from '../constants/pool.ts'
import { useAppSelector } from '../redux/hooks'
import { Hop } from '../utils/route.ts'

export interface ParsedGetRouteResponseData {
  srcAsset: Asset
  dstAsset: Asset
  srcAmount: string
  dstAmount: string
  paths: Hop[][]
  swapData: InputEntryFunctionData | undefined
}

export interface RawHop {
  srcAsset: string
  dstAsset: string
  srcAmount: string
  dstAmount: string
  source: string
}

export interface GetRouteResponseData {
  srcAsset: string
  dstAsset: string
  srcAmount: string
  dstAmount: string
  paths: RawHop[][]
  tx:
    | {
        function: string
        typeArguments: string[]
        functionArguments: any[]
      }
    | undefined
}

export interface GetRouteResponse {
  code: number
  message: string
  data: GetRouteResponseData
  requestId: string
}

const fn = async ({
  sender,
  srcAsset,
  dstAsset,
  srcAmount,
  slippageBps,
  includeSources,
  isFeeIn,
  feeInBps,
  feeReceiver,
}: {
  sender?: string
  srcAsset?: string
  dstAsset?: string
  srcAmount?: string
  slippageBps?: number
  includeSources?: string
  isFeeIn: boolean
  feeInBps: number
  feeReceiver: string
}): Promise<GetRouteResponseData | undefined> => {
  if (!srcAsset || !dstAsset || !srcAmount || parseFloat(srcAmount) == 0) return
  const response = await apiClient<GetRouteResponse>(`${AGGREGATOR_URL}/v1/quote`, {
    params: {
      srcAsset: srcAsset,
      dstAsset: dstAsset,
      amount: srcAmount,
      includeSources,
      sender,
      recipient: sender,
      slippage: slippageBps,
      isFeeIn,
      feeInBps,
      feeReceiver,
    },
  })
  if (response.status === 200 && response.data.data.dstAmount != '0') {
    return response.data.data
  }
  return undefined
}

export default function useQuote({
  isSwapping,
  sender,
  srcAsset,
  dstAsset,
  srcAmount,
  slippageBps,
  includeSources,
}: {
  isSwapping: boolean
  sender?: string
  srcAsset?: string
  dstAsset?: string
  srcAmount?: string
  slippageBps?: number
  includeSources?: string
}) {
  const [params] = useSearchParams()
  const isFeeIn = params.get('isFeeIn')
  const feeInBps = params.get('feeInBps')
  const feeReceiver = params.get('feeReceiver')

  const { data, error, isValidating, mutate } = useSWR(
    {
      key: 'useQuote',
      sender,
      srcAsset,
      dstAsset,
      srcAmount,
      slippageBps,
      includeSources,
      isFeeIn,
      feeInBps,
      feeReceiver,
    },
    fn,
    {
      isPaused: () => isSwapping,
    },
  )

  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const parsedData = useMemo(() => {
    if (!data) return undefined
    try {
      const paths: Hop[][] = []
      for (let i = 0; i < data.paths.length; i++) {
        paths.push([])
        for (let j = 0; j < data.paths[i].length; j++) {
          const h: RawHop = data.paths[i][j]
          const srcAsset = followingTokenData?.[h.srcAsset]
          invariant(srcAsset, 'srcAsset undefined')
          const dstAsset = followingTokenData?.[h.dstAsset]
          invariant(dstAsset, 'dstAsset undefined')
          const pool = SUPPORTED_POOLS[Object.keys(SUPPORTED_POOLS).find((p) => p === h.source) || ''] || {
            name: h.source,
            shortName: h.source,
            logoUrl: '',
          }
          paths[i].push({
            srcAsset,
            dstAsset,
            srcAmount: h.srcAmount,
            dstAmount: h.dstAmount,
            pool,
          })
        }
      }
      const srcAsset = followingTokenData[data.srcAsset]
      invariant(srcAsset, 'srcAsset undefined')
      const dstAsset = followingTokenData[data.dstAsset]
      invariant(dstAsset, 'dstAsset undefined')
      let swapData: InputEntryFunctionData | undefined
      if (data.tx) {
        swapData = {
          ...data.tx,
          functionArguments: data.tx.functionArguments,
        } as InputEntryFunctionData // TODO: Fix this later because I'm tired of types.
      }
      const res: ParsedGetRouteResponseData = {
        srcAsset,
        dstAsset,
        srcAmount: data.srcAmount,
        dstAmount: data.dstAmount,
        paths,
        swapData,
      }
      return res
    } catch (err) {
      console.error(err)
      return undefined
    }
  }, [data, followingTokenData])

  const res = useMemo(
    () => ({
      isValidating,
      error,
      dstAmount: parsedData?.dstAmount,
      paths: parsedData?.paths,
      swapData: parsedData?.swapData,
      reFetch: mutate,
    }),
    [error, isValidating, mutate, parsedData?.dstAmount, parsedData?.paths, parsedData?.swapData],
  )

  return res
}
