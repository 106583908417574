import { TokenImage } from '@/components/TokenImage.tsx'
import { SwapPanel } from '@/pages/swap/constant.ts'
import { Icon } from '@iconify/react'
import { Button, Skeleton, Spacer } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import MainMotion from '../../components/anim/MainMotion.tsx'
import { ButtonBase } from '../../components/Button.tsx'
import { Body0, Body2, Body4 } from '../../components/Typography.tsx'
import { Asset } from '../../constants/asset.ts'
import { Fraction } from '../../utils/fraction.ts'
import { numberWithCommas, truncateValue } from '../../utils/number.ts'

export const SwapForm = ({
  connected,
  onSetPercentAmountIn,
  fractionalBalanceTokenIn,
  setInputValue,
  setActivePanel,
  fractionalAmountInUsd,
  inputValue,
  tokenInInfo,
  fractionalBalanceTokenOut,
  switchToken,
  fractionalAmountIn,
  swapButton,
  openTradeRoute,
  openWalletConnect,
  fractionalAmountOut,
  setShowTradeDetails,
  isValidatingQuote,
  onSwap,
  priceImpact,
  tokenOutInfo,
  rate,
  isShowTradeDetails,
  minimumReceived,
  readableAmountOut,
  fractionalAmountOutUsd,
}: {
  connected: boolean
  onSetPercentAmountIn: (v: number) => void
  fractionalBalanceTokenIn: Fraction | undefined
  setInputValue: (v: string, decimals: number | undefined) => void
  setActivePanel: (v: string) => void
  fractionalAmountInUsd: Fraction | undefined
  inputValue: string
  tokenInInfo: Asset | undefined
  fractionalBalanceTokenOut: Fraction | undefined
  switchToken: () => void
  fractionalAmountIn: Fraction | undefined
  swapButton: { isDisabled: boolean; text: string }
  openTradeRoute: () => void
  openWalletConnect: () => void
  fractionalAmountOut: Fraction | undefined
  setShowTradeDetails: (v: boolean) => void
  isValidatingQuote: boolean
  onSwap: () => void
  priceImpact: Fraction | undefined
  tokenOutInfo: Asset | undefined
  rate: Fraction | undefined
  isShowTradeDetails: boolean
  minimumReceived: Fraction | undefined
  readableAmountOut: string
  fractionalAmountOutUsd: Fraction | undefined
}) => {
  const [isInvert, setIsInvert] = useState(false)

  const isPriceImpactVeryHigh = useMemo(() => Boolean(priceImpact?.greaterThan(10)), [priceImpact])
  const isPriceImpactHigh = useMemo(() => Boolean(priceImpact?.greaterThan(5)), [priceImpact])
  return (
    <MainMotion skipInitialAnimation="swapForm">
      <div className="relative flex flex-col gap-1">
        {/* INPUT */}
        <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
          <div className="flex items-center justify-between">
            <Body4 className="text-baseGrey">You&apos;re Paying</Body4>
            {connected && (
              <Button
                className="flex h-fit w-fit min-w-fit items-center gap-1 bg-transparent p-0"
                disableAnimation
                disableRipple
                onClick={() => onSetPercentAmountIn(100)}
              >
                <Icon icon="mdi:wallet" color="#8B8D91" fontSize={14.08} />
                <Body4 className="text-baseGrey">
                  {fractionalBalanceTokenIn ? numberWithCommas(fractionalBalanceTokenIn.toSignificant(6)) : '0'}
                </Body4>
              </Button>
            )}
          </div>
          <div className="flex items-center justify-between gap-3">
            <NumericFormat
              decimalSeparator="."
              allowedDecimalSeparators={[',']}
              thousandSeparator
              inputMode="decimal"
              autoComplete="off"
              autoCorrect="off"
              type="text"
              placeholder="0.00"
              minLength={1}
              maxLength={30}
              spellCheck="false"
              className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey sm:text-[30px]"
              pattern="^[0-9]*[.,]?[0-9]*$"
              value={inputValue}
              allowNegative={false}
              onChange={(e) => setInputValue(e.currentTarget.value, tokenInInfo?.decimals)}
            />
            <Button
              className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey bg-background p-0 transition"
              disableAnimation
              disableRipple
              onPress={() => setActivePanel(SwapPanel.SelectTokenIn)}
            >
              <div className="ml-1.5">
                <TokenImage
                  width={20}
                  height={20}
                  className="min-h-[20px] min-w-[20p]"
                  logoUrl={tokenInInfo?.logoUrl}
                />
              </div>
              <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">{tokenInInfo?.symbol ?? '--'}</Body0>
              <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
            </Button>
          </div>
          <div className="flex items-center justify-between gap-3">
            <Body4 className="text-baseGrey">
              {fractionalAmountInUsd ? '~$' + numberWithCommas(fractionalAmountInUsd.toSignificant(6), false, 2) : '--'}
            </Body4>
          </div>
        </div>
        {/* SWITCH TOKEN */}
        <div className="absolute left-1/2 top-1/2 z-[1] -translate-x-1/2 -translate-y-1/2">
          <Button
            isIconOnly
            className="h-[36px] min-h-[36px] w-[36px] min-w-[36px] rounded-lg border-[0.5px] border-borderGrey bg-background"
            onPress={switchToken}
          >
            <Icon icon="mdi:arrow-down" color="#8B8D91" fontSize={22} />
          </Button>
        </div>
        {/* OUTPUT */}
        <>
          <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
            <div className="flex items-center justify-between">
              <Body4 className="text-baseGrey">To Receive</Body4>
              {connected && (
                <Button
                  className="flex h-fit w-fit min-w-fit items-center gap-1 bg-transparent p-0"
                  disableAnimation
                  disableRipple
                >
                  <Icon icon="mdi:wallet" color="#8B8D91" fontSize={14.08} />
                  <Body4 className="text-baseGrey">
                    {fractionalBalanceTokenOut ? numberWithCommas(fractionalBalanceTokenOut.toSignificant(6)) : '0'}
                  </Body4>
                </Button>
              )}
            </div>
            <div className="flex items-center justify-between gap-3">
              <NumericFormat
                decimalSeparator="."
                allowedDecimalSeparators={[',']}
                thousandSeparator
                inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                type="text"
                placeholder="0.00"
                minLength={1}
                maxLength={30}
                spellCheck="false"
                className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey sm:text-[30px]"
                style={{ color: '#8B8D91', WebkitTextFillColor: '#8B8D91' }}
                pattern="^[0-9]*[.,]?[0-9]*$"
                disabled
                data-tooltip-id="tooltip-input-amount-out"
                value={readableAmountOut}
              />
              <Button
                className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey bg-background p-0 transition"
                disableAnimation
                disableRipple
                onPress={() => setActivePanel(SwapPanel.SelectTokenOut)}
              >
                <div className="ml-1.5">
                  <TokenImage
                    width={20}
                    height={20}
                    className="min-h-[20px] min-w-[20p]"
                    logoUrl={tokenOutInfo?.logoUrl}
                  />
                </div>
                <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">{tokenOutInfo?.symbol ?? '--'}</Body0>
                <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
              </Button>
            </div>
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center justify-between gap-1">
                <Body4 className="text-baseGrey">
                  {fractionalAmountOutUsd
                    ? '~$' + numberWithCommas(fractionalAmountOutUsd.toSignificant(6), false, 2)
                    : '--'}
                </Body4>
                {priceImpact?.greaterThan(new Fraction(1, 100)) && (
                  <Body4
                    data-tooltip-id="tooltip-price-impact"
                    className={
                      isPriceImpactVeryHigh ? 'text-error' : isPriceImpactHigh ? 'text-primary' : 'text-baseGrey'
                    }
                  >
                    ({`-${truncateValue(priceImpact.toSignificant(4), 2)}%`})
                  </Body4>
                )}
              </div>
              {priceImpact === undefined && !isValidatingQuote ? (
                <div className="flex items-center gap-1">
                  <Icon icon="ph:warning" fontSize={14} color="#F44646" />
                  <Body4 className="text-error">Can&apos;t calculate price impact</Body4>
                </div>
              ) : isPriceImpactVeryHigh ? (
                <div className="flex items-center gap-1">
                  <Icon icon="ph:warning" fontSize={14} color="#F44646" />
                  <Body4 className="text-error">Price impact is very high</Body4>
                </div>
              ) : isPriceImpactHigh ? (
                <div className="flex items-center gap-1">
                  <Icon icon="ph:warning" fontSize={14} color="#FF9901" />
                  <Body4 className="text-primary">Price impact is high</Body4>
                </div>
              ) : null}
            </div>
          </div>
        </>
      </div>

      <Spacer y={1} />

      {connected ? (
        <ButtonBase
          v={(fractionalAmountIn && fractionalAmountOut && !priceImpact) || isPriceImpactVeryHigh ? 'error' : 'primary'}
          className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
          onPress={onSwap}
          isDisabled={swapButton.isDisabled}
        >
          <Body2>{swapButton.text}</Body2>
        </ButtonBase>
      ) : (
        <ButtonBase
          v="primary"
          className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
          onPress={openWalletConnect}
        >
          <Body2>Connect Wallet</Body2>
        </ButtonBase>
      )}

      <Spacer y={3} />

      {fractionalAmountIn && fractionalAmountOut && (
        <>
          <div
            onClick={() => setShowTradeDetails(!isShowTradeDetails)}
            className="flex flex-col overflow-hidden rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1"
          >
            <div className="flex justify-between">
              <div className="ml-3 flex items-center gap-2 overflow-hidden">
                {isValidatingQuote ? (
                  <>
                    <div className="flex h-[14.08px] w-[150px] items-center">
                      <Skeleton className="h-[14.08px] w-full rounded bg-baseGrey" />
                    </div>
                  </>
                ) : isInvert ? (
                  <Button
                    onPress={() => setIsInvert(!isInvert)}
                    variant="light"
                    className="m-0 h-fit gap-0 p-0 data-[hover]:bg-transparent"
                    disableAnimation
                    disableRipple
                  >
                    <Body4 className="overflow-hidden text-ellipsis whitespace-nowrap text-white">
                      {rate
                        ? `1 ${tokenOutInfo?.symbol ?? '--'} = ${numberWithCommas(rate.invert().toSignificant(6))} ${tokenInInfo?.symbol ?? '--'}`
                        : '--'}
                    </Body4>
                  </Button>
                ) : (
                  <Button
                    onPress={() => setIsInvert(!isInvert)}
                    variant="light"
                    className="m-0 h-fit gap-0 p-0 data-[hover]:bg-transparent"
                    disableAnimation
                    disableRipple
                  >
                    <Body4 className="overflow-hidden text-ellipsis whitespace-nowrap text-white">
                      {rate
                        ? `1 ${tokenInInfo?.symbol ?? '--'} = ${numberWithCommas(rate.toSignificant(6))} ${tokenOutInfo?.symbol ?? '--'}`
                        : '--'}
                    </Body4>
                  </Button>
                )}
              </div>
              <Button
                variant="light"
                className="m-0 flex  h-[39.08px] w-[50px] min-w-fit cursor-pointer justify-end gap-0 py-0 pl-0 pr-3 text-right data-[hover]:bg-transparent"
                disableAnimation
                disableRipple
                onPress={() => setShowTradeDetails(!isShowTradeDetails)}
              >
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: isShowTradeDetails ? '180deg' : 0 }}
                  className="min-w-[20px]"
                >
                  <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={20} className="-mr-1" />
                </motion.div>
              </Button>
            </div>
            <motion.div
              initial={{ height: 0, opacity: 0, display: 'none' }}
              animate={{
                height: isShowTradeDetails ? 'fit-content' : 0,
                opacity: isShowTradeDetails ? 1 : 0,
                display: 'flex',
                marginBottom: isShowTradeDetails ? '12px' : '0',
              }}
              transition={{ duration: 0.25 }}
              className="mx-3 flex flex-col overflow-hidden"
            >
              <div className="flex items-center justify-between py-1">
                <div className="flex items-center gap-1">
                  <Body4 className="text-baseGrey">Price Impact</Body4>
                </div>
                {isValidatingQuote ? (
                  <>
                    <div className="flex h-[14.08px] w-[100px] items-center">
                      <Skeleton className="h-[14.08px] w-full rounded bg-baseGrey" />
                    </div>
                  </>
                ) : (
                  <Body4 className="text-white">
                    {priceImpact
                      ? priceImpact.equalTo(0)
                        ? '0%'
                        : `-${truncateValue(priceImpact.toSignificant(4), 2)}%`
                      : '--'}
                  </Body4>
                )}
              </div>
              <div className="flex items-center justify-between py-1 ">
                <div className="flex items-center gap-1">
                  <Body4 className="text-baseGrey">Minimum Received</Body4>
                </div>
                {isValidatingQuote ? (
                  <>
                    <div className="flex h-[14.08px] w-[100px] items-center">
                      <Skeleton className="h-[14.08px] w-full rounded bg-baseGrey" />
                    </div>
                  </>
                ) : (
                  <Body4 className="text-white">
                    {minimumReceived && tokenOutInfo
                      ? `${numberWithCommas(minimumReceived.toSignificant(6))} ${tokenOutInfo.symbol ?? '--'}`
                      : '--'}
                  </Body4>
                )}
              </div>
              <div className="flex min-h-[22px] items-center justify-between">
                <Body4 className="text-baseGrey">Trade Route</Body4>
                <Button
                  variant="light"
                  className=":hover:bg-transparent m-0 h-fit w-fit min-w-fit gap-0 bg-transparent p-0"
                  disableAnimation
                  disableRipple
                  onPress={openTradeRoute}
                >
                  <Icon icon="flowbite:expand-outline" color="#FFFFFF" fontSize={18} />
                </Button>
              </div>
            </motion.div>
          </div>
          <Spacer y={4} />
        </>
      )}
    </MainMotion>
  )
}
