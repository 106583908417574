import { OrderBookConfig } from '@/hooks/contract/useGetOrderBookConfig'

const LIMIT_ORDER: Record<string, OrderBookConfig> = {
  '0': {
    idx: '0',
    base_type_name: '0x1::aptos_coin::AptosCoin',
    quote_type_name: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::USDC',
    lot_size: '1000000',
    tick_size: '100000',
  },
}

export function getLimitOrderById(id: string): OrderBookConfig {
  return LIMIT_ORDER[id]
}

export const IS_SUPPORT_LO = false
// window.location.origin === 'https://mosaic-interface-pink.vercel.app' || window.location.host === 'localhost:9574'

export const IS_SUPPORT_POOL =
  window.location.origin === 'https://mosaic-interface-pink.vercel.app' || window.location.host === 'localhost:9574'
