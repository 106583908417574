import { Asset } from '../constants/asset.ts'
import { SUPPORTED_POOLS } from '../constants/pool.ts'
import { ValueOf } from '../types.ts'

export interface Hop {
  srcAsset: Asset
  dstAsset: Asset
  srcAmount: string
  dstAmount: string
  pool: ValueOf<typeof SUPPORTED_POOLS>
}

export interface PoolWithAmount {
  amount: string
  pool: ValueOf<typeof SUPPORTED_POOLS>
}

export interface Route {
  srcAsset: Asset
  dstAsset: Asset
  srcAmount: string
  dstAmount: string
  pools: PoolWithAmount[]
  next: Route[]
}

export function pathToRoutes(paths: Hop[][]): Route[] {
  const routes: Route[] = []

  for (const path of paths) {
    let currentRoute = routes

    for (const hop of path) {
      const existingRoute = currentRoute.find((r) => r.dstAsset.id === hop.dstAsset.id)

      if (existingRoute) {
        updateExistingRoute(existingRoute, hop)
        currentRoute = existingRoute.next
      } else {
        const newRoute = createNewRoute(hop)
        currentRoute.push(newRoute)
        currentRoute = newRoute.next
      }
    }
  }

  return routes
}

function updateExistingRoute(route: Route, hop: Hop): void {
  route.srcAmount = (BigInt(route.srcAmount) + BigInt(hop.srcAmount)).toString()
  route.dstAmount = (BigInt(route.dstAmount) + BigInt(hop.dstAmount)).toString()
  updateOrAddPool(route.pools, hop)
}

function createNewRoute(hop: Hop): Route {
  return {
    srcAsset: hop.srcAsset,
    dstAsset: hop.dstAsset,
    srcAmount: hop.srcAmount,
    dstAmount: hop.dstAmount,
    pools: [{ amount: hop.srcAmount, pool: hop.pool }],
    next: [],
  }
}

function updateOrAddPool(pools: PoolWithAmount[], hop: Hop): void {
  const existingPool = pools.find((p) => p.pool?.name === hop.pool?.name)
  if (existingPool) {
    existingPool.amount = (BigInt(existingPool.amount) + BigInt(hop.srcAmount)).toString()
  } else {
    pools.push({ pool: hop.pool, amount: hop.srcAmount })
  }
}
