import type { Event } from '@aptos-labs/ts-sdk'

export interface SwapEvent {
  sender: string
  input_asset: string
  output_asset: string
  input_amount: string
  output_amount: string
  timestamp: string
  extra_data: string
}

const SWAP_EVENT_TYPES = ['::router::SwapEvent']

export function isMosaicSwapEvent(event: string): boolean {
  for (let i = 0; i < SWAP_EVENT_TYPES.length; i++) {
    if (event.includes(SWAP_EVENT_TYPES[i])) return true
  }
  return false
}

export function parse_amount_out_from_event(events: Event[]): string {
  try {
    const swap_event = events
      .filter((event) => isMosaicSwapEvent(event.type))
      .map((event) => event.data as SwapEvent)[0]
    if (!swap_event) return '0'
    return swap_event.output_amount
  } catch {
    return '0'
  }
}
