import { Image } from '@nextui-org/react'
import { useEffect, useState } from 'react'

export function TokenImage({
  width,
  height,
  className,
  logoUrl,
}: {
  width?: number
  height?: number
  className?: string
  logoUrl?: string
}) {
  const emptyLogoUrl = '/images/404.svg'
  const [tokenLogoSrc, setTokenLogoSrc] = useState(logoUrl || emptyLogoUrl)

  useEffect(() => {
    setTokenLogoSrc(logoUrl || emptyLogoUrl)
  }, [logoUrl])

  return (
    <Image
      width={width || 20}
      height={height || 20}
      className={`${className || 'min-h-[20px] min-w-[20px]'} ${tokenLogoSrc === emptyLogoUrl ? 'bg-white' : ''}`}
      src={tokenLogoSrc}
      onError={() => setTokenLogoSrc(emptyLogoUrl)}
    />
  )
}
