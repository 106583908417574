import { Asset } from '@/constants/asset.ts'
import { isUserTransactionResponse } from '@aptos-labs/ts-sdk'
import invariant from 'invariant'
import { useCallback } from 'react'
import { aptos } from '../constants'
import useMovementWallet from './useMovementWallet.ts'
import { useShowToastWithExplorerLink } from './useSwapNotificationFn.tsx'

export default function useMigrateToken() {
  const { account, signAndSubmitTransaction } = useMovementWallet()

  const sendNotification = useShowToastWithExplorerLink()

  return useCallback(
    async ({ token }: { token: Asset }) => {
      if (!account || !token?.coinType) return

      try {
        const submitResponse = await signAndSubmitTransaction({
          payload: {
            function: `0x0000000000000000000000000000000000000000000000000000000000000001::coin::migrate_to_fungible_store`,
            functionArguments: [],
            typeArguments: [token.coinType],
          },
        })

        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invariant(submitResponse.status === 'Approved', 'User rejected transaction')
        const hash = submitResponse.args.hash

        const receipt = await aptos.waitForTransaction({
          transactionHash: hash,
          options: { checkSuccess: false, waitForIndexer: false, timeoutSecs: 5000 },
        })
        invariant(isUserTransactionResponse(receipt), 'isUserTransactionResponse(receipt) failed')
        sendNotification({ isSuccess: true, txHash: hash, msg: `Migrate ${token.symbol} successfully` })
      } catch (err) {
        console.error(err)
        sendNotification({
          isSuccess: false,
          msg: `Migrate ${token.symbol} failed`,
          error: err,
        })
      }
    },
    [account, signAndSubmitTransaction, sendNotification],
  )
}
