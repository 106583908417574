import { SwapForm } from '@/components/swap/SwapForm.tsx'
import { IS_SUPPORT_LO } from '@/constants/limitOrder.ts'
import APP_PATHS from '@/constants/path.ts'
import { Button, Spacer } from '@nextui-org/react'
import { AptosWalletContextState } from '@razorlabs/wallet-kit'
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import CountdownSpinner from '../../components/CountdownSpinner.tsx'
import { SettingIcon } from '../../components/Icons.tsx'
import { MODAL_LIST } from '../../components/modals/constant.ts'
import ModalTradeRoute from '../../components/modals/ModalTradeRoute.tsx'
import { SwapLimitTab } from '../../components/SwapLimitTab.tsx'
import TokenSelector from '../../components/TokenSelector.tsx'
import { BIP_BASE } from '../../constants'
import { Asset, MOVE, USDC } from '../../constants/asset.ts'
import { ALL_DEXES } from '../../constants/pool.ts'
import useFullTokens from '../../hooks/useFullTokens.ts'
import useMovementNetworkStatus from '../../hooks/useMovementNetworkStatus.ts'
import useQuote from '../../hooks/useQuote.ts'
import { useTokenBalance } from '../../hooks/useRefreshBalanceFn.ts'
import useSwap from '../../hooks/useSwap.tsx'
import { useModal } from '../../provider/ModalProvider.tsx'
import { useAppSelector } from '../../redux/hooks'
import { useLiquiditySource } from '../../redux/slices/hooks.ts'
import { Fraction } from '../../utils/fraction.ts'
import {
  escapeRegExp,
  inputRegex,
  mulpowToFraction,
  numberWithCommas,
  parseFraction,
  truncateValue,
} from '../../utils/number.ts'
import { SwapPanel } from './constant.ts'
import DexSelector from './DexSelector.tsx'
import SwapSettings from './SwapSettings.tsx'

export const getIdFromUrl = (pathname: string) => {
  const pair = pathname.replace(`${APP_PATHS.SWAP}/`, '')
  const tokenInSymbolOrAddress = pair.split('-')[0]
  const tokenOutSymbolOrAddress = pair.split('-')[1]
  return { tokenInSymbolOrAddress, tokenOutSymbolOrAddress }
}

export const getSlug = (token: Asset | undefined) => (token?.whitelisted ? token?.symbol : token?.id) ?? ''

type Style = string | CSSProperties

type SelectFunc = (token: Asset) => void

type SelectState = {
  tokenIn: Asset | undefined
  tokenOut: Asset | undefined
  onChangeTokenIn: SelectFunc
  onChangeTokenOut: SelectFunc
  onSwitchToken: (newTokenIn: Asset, newTokenOut: Asset) => void
}

type LocalStateProps = {
  useLocalState: true // default true, if you set to true, you need to specify all: tokenIn, tokenOut, onChangeTokenIn, onChangeTokenOut
} & SelectState

type GlobalStateProps = {
  useLocalState?: false
} & Partial<SelectState>

type WidgetProps = {
  wallet: AptosWalletContextState
  defaultInputAmount?: string
  style?: {
    container?: Style
  }
} & (LocalStateProps | GlobalStateProps)

const useBalances = ({
  tokenInInfo,
  tokenOutInfo,
}: {
  tokenInInfo: Asset | undefined
  tokenOutInfo: Asset | undefined
}) => {
  const balanceTokenIn = useTokenBalance(tokenInInfo)
  const fractionalBalanceTokenIn = useMemo(
    () => parseFraction(balanceTokenIn, tokenInInfo?.decimals),
    [balanceTokenIn, tokenInInfo],
  )
  const balanceTokenOut = useTokenBalance(tokenOutInfo)

  const fractionalBalanceTokenOut = useMemo(
    () => parseFraction(balanceTokenOut, tokenOutInfo?.decimals),
    [balanceTokenOut, tokenOutInfo],
  )

  return { fractionalBalanceTokenIn, fractionalBalanceTokenOut }
}

const useFindTokenDefault = () => {
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const { data: fullTokenData } = useFullTokens()
  return useCallback(() => {
    const fullTokenDataList = Object.values(fullTokenData || {})
    if (!fullTokenDataList.length) return { newTokenIn: undefined, newTokenOut: undefined }

    const followingTokenDataList = Object.values(followingTokenData)

    const findFn = (id: string) =>
      followingTokenDataList.find((token) => token?.id === id) || fullTokenDataList.find((token) => token?.id === id)

    const newTokenIn = findFn(MOVE.id)
    const newTokenOut = findFn(USDC.id)
    return { newTokenIn, newTokenOut }
  }, [followingTokenData, fullTokenData])
}

function SwapWidget({
  wallet,
  defaultInputAmount = '1',
  onChangeTokenIn,
  onChangeTokenOut,
  tokenIn,
  tokenOut,
  onSwitchToken,
  useLocalState = true,
}: WidgetProps) {
  const { globalModal, isModalOpen, onOpenModal, onCloseModal, onOpenChangeModal } = useModal()
  const { address: walletAddress } = wallet

  const resetTimerFunction = useRef(() => {})
  const setResetTimerFunc = (f: () => void) => (resetTimerFunction.current = f)

  const { isNetworkStable, isLoadingNetworkStatus } = useMovementNetworkStatus()

  const [typedAmountIn, _setTypedAmountIn] = useState(defaultInputAmount)
  const [shouldUseDebounceAmountIn, setShouldUseDebounceAmountIn] = useState(true)
  const setTypedAmountIn = useCallback((value: string, decimals = 8, shouldUseDebounce = true) => {
    setShouldUseDebounceAmountIn(shouldUseDebounce)
    if (value?.endsWith(',')) {
      value = value.slice(0, value.length - 1) + '.'
    }
    value = value.replaceAll(',', '')
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      value = truncateValue(value, decimals)
      if (value.length && value.startsWith('.')) value = '0.'
      value = numberWithCommas(value)
      _setTypedAmountIn(value)
    }
  }, [])

  const [_tokenInInfo, _setTokenInInfo] = useState<Asset>()
  const [_tokenOutInfo, _setTokenOutInfo] = useState<Asset>()

  const tokenInInfo = !useLocalState ? tokenIn : _tokenInInfo
  const tokenOutInfo = !useLocalState ? tokenOut : _tokenOutInfo

  const setTokenInInfo = useCallback(
    (token: Asset | undefined) => {
      !useLocalState ? token && onChangeTokenIn?.(token) : _setTokenInInfo(token)
    },
    [onChangeTokenIn, useLocalState],
  )
  const setTokenOutInfo = useCallback(
    (token: Asset | undefined) => {
      !useLocalState ? token && onChangeTokenOut?.(token) : _setTokenOutInfo(token)
    },
    [onChangeTokenOut, useLocalState],
  )

  const tokenInId = tokenInInfo?.id || MOVE.id
  const tokenOutId = tokenOutInfo?.id || USDC.id

  const findTokensFromUrl = useFindTokenDefault()
  const init = useRef(false)
  useEffect(() => {
    if (init.current || !useLocalState) return
    const { newTokenIn, newTokenOut } = findTokensFromUrl()
    if (!newTokenIn || !newTokenOut) return
    init.current = true
    setTokenInInfo(newTokenIn)
    setTokenOutInfo(newTokenOut)
  }, [findTokensFromUrl, setTokenInInfo, setTokenOutInfo, useLocalState])

  const tokenInDecimals = tokenInInfo ? tokenInInfo.decimals : undefined
  const tokenOutDecimals = tokenOutInfo ? tokenOutInfo.decimals : undefined

  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)
  const fractionalPriceTokenIn = useMemo(
    () => (followingPriceData[tokenInId] ? mulpowToFraction(followingPriceData[tokenInId]) : undefined),
    [followingPriceData, tokenInId],
  )
  const fractionalPriceTokenOut = useMemo(
    () => (followingPriceData[tokenOutId] ? mulpowToFraction(followingPriceData[tokenOutId]) : undefined),
    [followingPriceData, tokenOutId],
  )

  const { fractionalBalanceTokenIn, fractionalBalanceTokenOut } = useBalances({ tokenInInfo, tokenOutInfo })

  const _fractionalAmountIn = useMemo(
    () =>
      typedAmountIn && tokenInDecimals !== undefined
        ? mulpowToFraction(typedAmountIn.replaceAll(',', ''), tokenInDecimals)
        : undefined,
    [tokenInDecimals, typedAmountIn],
  )
  const [fractionalAmountIn] = useDebounceValue(_fractionalAmountIn, shouldUseDebounceAmountIn ? 100 : 0)

  const { source, setLiquiditySource: setSource, totalSource } = useLiquiditySource(true)

  const slippageBps = useAppSelector((state) => state.user.slippageBps)

  const { isSwapping, onSwap: _onSwap } = useSwap()

  const {
    dstAmount,
    isValidating: isValidatingQuote,
    paths,
    swapData,
    reFetch,
  } = useQuote({
    isSwapping,
    sender: walletAddress,
    srcAsset: tokenInId,
    dstAsset: tokenOutId,
    srcAmount: fractionalAmountIn?.numerator?.toString(),
    slippageBps,
    includeSources: totalSource === ALL_DEXES.length ? '' : source,
  })
  const fractionalAmountOut = useMemo(() => parseFraction(dstAmount, tokenOutDecimals), [dstAmount, tokenOutDecimals])

  const readableAmountOut =
    fractionalAmountOut && tokenOutDecimals !== undefined
      ? numberWithCommas(truncateValue(fractionalAmountOut.toFixed(tokenOutDecimals), tokenOutDecimals))
      : ''

  const fractionalAmountInUsd = useMemo(
    () => (fractionalPriceTokenIn ? fractionalAmountIn?.multiply(fractionalPriceTokenIn) : undefined),
    [fractionalAmountIn, fractionalPriceTokenIn],
  )
  const fractionalAmountOutUsd = useMemo(
    () => (fractionalPriceTokenOut ? fractionalAmountOut?.multiply(fractionalPriceTokenOut) : undefined),
    [fractionalAmountOut, fractionalPriceTokenOut],
  )

  const rate = useMemo(
    () => (fractionalAmountIn ? fractionalAmountOut?.divide(fractionalAmountIn) : undefined),
    [fractionalAmountIn, fractionalAmountOut],
  )
  const priceImpact = useMemo(() => {
    let res =
      fractionalAmountInUsd && fractionalAmountOutUsd
        ? fractionalAmountInUsd.subtract(fractionalAmountOutUsd).divide(fractionalAmountInUsd).multiply(100)
        : undefined

    if (res?.lessThan(0)) {
      res = new Fraction(0)
    }
    return res
  }, [fractionalAmountInUsd, fractionalAmountOutUsd])

  const minimumReceived = useMemo(() => {
    if (!fractionalAmountOut) return undefined
    // If any tokens have more than 8 decimals, this assignment will break. I assume 8 is the max decimals in aptos chain? Never mind, I will use 18.
    const str = fractionalAmountOut
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    const res = mulpowToFraction(str, tokenOutDecimals) // To cut redundant decimals.
    return res
  }, [fractionalAmountOut, slippageBps, tokenOutDecimals])

  const fractionalFeeAmount = useMemo(
    () => (tokenInId === MOVE.id ? new Fraction(2, 1000) : new Fraction(0, 1)),
    [tokenInId],
  )
  const isSufficientBalance =
    fractionalBalanceTokenIn && fractionalAmountIn
      ? fractionalBalanceTokenIn.subtract(fractionalFeeAmount).equalTo(fractionalAmountIn) ||
        fractionalBalanceTokenIn.subtract(fractionalFeeAmount).greaterThan(fractionalAmountIn)
      : undefined

  const onSetPercentAmountIn = (percent: number) => {
    if (!fractionalBalanceTokenIn || !fractionalFeeAmount) {
      setTypedAmountIn('', tokenInDecimals, false)
      return
    }
    let newTypedAmountIn = fractionalBalanceTokenIn.multiply(percent).divide(100)
    if (fractionalBalanceTokenIn.subtract(fractionalFeeAmount).lessThan(newTypedAmountIn)) {
      newTypedAmountIn = newTypedAmountIn.subtract(fractionalFeeAmount)
    }
    if (newTypedAmountIn.greaterThan(0)) {
      const newTypedAmountInStr = newTypedAmountIn.toFixed(18)
      setTypedAmountIn(newTypedAmountInStr, tokenInDecimals, false)
    } else {
      setTypedAmountIn('', tokenInDecimals, false)
    }
  }

  const swapButton = useMemo(() => {
    if (isSwapping) return { isDisabled: true, text: 'Swapping...' }
    if (isLoadingNetworkStatus) return { isDisabled: true, text: 'Checking network status...' }
    if (!isNetworkStable) return { isDisabled: true, text: 'Network is not stable now' }
    if (!fractionalAmountIn) return { isDisabled: true, text: 'Enter an amount' }
    if (!isSufficientBalance) return { isDisabled: true, text: 'Insufficient balance' }
    if (isValidatingQuote) return { isDisabled: true, text: 'Getting quote...' }
    if (!fractionalAmountOut) return { isDisabled: true, text: 'Not found route' }
    return { isDisabled: false, text: 'Swap' }
  }, [
    isLoadingNetworkStatus,
    isNetworkStable,
    fractionalAmountIn,
    isSufficientBalance,
    isValidatingQuote,
    fractionalAmountOut,
    isSwapping,
  ])

  const switchToken = useCallback(() => {
    if (fractionalAmountOut && tokenOutDecimals !== undefined) {
      setTypedAmountIn(
        truncateValue(fractionalAmountOut.toFixed(tokenOutDecimals), tokenOutDecimals),
        tokenOutDecimals,
        false,
      )
    } else {
      setTypedAmountIn('')
    }
    setTokenInInfo(tokenOutInfo)
    setTokenOutInfo(tokenInInfo)
    if (tokenOutInfo && tokenInInfo) onSwitchToken?.(tokenOutInfo, tokenInInfo)
  }, [
    fractionalAmountOut,
    setTokenInInfo,
    setTokenOutInfo,
    tokenOutInfo,
    tokenInInfo,
    setTypedAmountIn,
    tokenOutDecimals,
    onSwitchToken,
  ])

  const [activePanel, setActivePanel] = useState(SwapPanel.Swap)

  const setTokenIn = useCallback(
    (token: Asset) => {
      setTokenInInfo(token)
      if (tokenOutId === token?.id) {
        switchToken()
      }
      setActivePanel(SwapPanel.Swap)
    },
    [switchToken, tokenOutId, setTokenInInfo],
  )
  const setTokenOut = useCallback(
    (token: Asset) => {
      setTokenOutInfo(token)
      if (tokenInId === token?.id) {
        switchToken()
      }
      setActivePanel(SwapPanel.Swap)
    },
    [switchToken, tokenInId, setTokenOutInfo],
  )

  const swapCardRef = useRef<HTMLDivElement>(null)

  const onSwap = () => {
    if (fractionalAmountIn && fractionalAmountOut && swapData) {
      void _onSwap({
        tokenIn: tokenInId,
        tokenOut: tokenOutId,
        amountIn: fractionalAmountIn.numerator.toString(),
        amountOut: fractionalAmountOut.numerator.toString(),
        swapData,
      })
    }
  }

  useEffect(() => {
    resetTimerFunction.current()
  }, [fractionalAmountIn, tokenInId, tokenOutId, isValidatingQuote])

  const [isShowTradeDetails, setShowTradeDetails] = useState(false)

  const backToSwap = () => setActivePanel(SwapPanel.Swap)

  return (
    <div className="mx-auto flex max-w-[450px] flex-col" ref={swapCardRef}>
      <div className="flex justify-between">
        {IS_SUPPORT_LO ? <SwapLimitTab /> : <div />}

        <div>
          <Button
            isIconOnly
            className={'h-[32px] w-[32px] min-w-min bg-transparent'}
            disableAnimation
            onPress={async () => {
              if (!isValidatingQuote) await reFetch()
            }}
          >
            <CountdownSpinner
              timeInSeconds={10}
              onFinishCountdown={reFetch}
              setResetTimerFunc={setResetTimerFunc}
              isLoading={isValidatingQuote || isSwapping}
              size={25}
            />
          </Button>
          <Button
            isIconOnly
            className="m-0 h-[32px] w-[32px] min-w-min bg-transparent p-0"
            onPress={() =>
              setActivePanel(activePanel === SwapPanel.SwapSettings ? SwapPanel.Swap : SwapPanel.SwapSettings)
            }
            disableAnimation
          >
            <SettingIcon size={24} color={'#8B8D91'} />
          </Button>
        </div>
      </div>

      <Spacer y={2} />

      {activePanel === SwapPanel.Swap && (
        <SwapForm
          {...{
            connected: !!walletAddress,
            onSetPercentAmountIn,
            fractionalBalanceTokenIn,
            setInputValue: setTypedAmountIn,
            setActivePanel,
            fractionalAmountInUsd,
            inputValue: typedAmountIn,
            tokenInInfo,
            fractionalBalanceTokenOut,
            switchToken,
            fractionalAmountIn,
            swapButton,
            onOpenModal,
            openWalletConnect: () => onOpenModal(MODAL_LIST.CONNECT_WALLET),
            openTradeRoute: () => onOpenModal(MODAL_LIST.TRADE_ROUTE),
            fractionalAmountOut,
            setShowTradeDetails,
            isValidatingQuote,
            onSwap,
            priceImpact,
            tokenOutInfo,
            rate,
            isShowTradeDetails,
            minimumReceived,
            readableAmountOut,
            fractionalAmountOutUsd,
          }}
        />
      )}

      {activePanel === SwapPanel.SelectTokenIn && (
        <TokenSelector
          swapCardRef={swapCardRef}
          onSelectToken={setTokenIn}
          onBack={() => setActivePanel(SwapPanel.Swap)}
        />
      )}

      {activePanel === SwapPanel.SelectTokenOut && (
        <TokenSelector
          swapCardRef={swapCardRef}
          onSelectToken={setTokenOut}
          onBack={() => setActivePanel(SwapPanel.Swap)}
        />
      )}

      {activePanel === SwapPanel.SwapSettings && (
        <SwapSettings
          swapCardRef={swapCardRef}
          onBack={backToSwap}
          openLiquidity={() => setActivePanel(SwapPanel.DexSelector)}
        />
      )}
      {activePanel === SwapPanel.DexSelector && (
        <DexSelector
          swapCardRef={swapCardRef}
          onBack={() => setActivePanel(SwapPanel.SwapSettings)}
          source={source}
          setSource={setSource}
        />
      )}
      <ModalTradeRoute
        isOpen={globalModal === MODAL_LIST.TRADE_ROUTE && isModalOpen}
        onOpenChange={onOpenChangeModal}
        onClose={onCloseModal}
        srcCoinType={tokenInId}
        dstCoinType={tokenOutId}
        readableAmountIn={numberWithCommas(typedAmountIn)}
        readableAmountOut={readableAmountOut}
        rawAmountIn={fractionalAmountIn?.numerator?.toString()}
        paths={paths}
      />
    </div>
  )
}

export default SwapWidget
