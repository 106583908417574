import { Button, Image } from '@nextui-org/react'
import { getWalletImagePath } from '../constants/wallet.ts'
import { useIsMd } from '../hooks/useMedia.ts'
import useMovementWallet from '../hooks/useMovementWallet.ts'
import { useModal } from '../provider/ModalProvider.tsx'
import { ButtonBase } from './Button.tsx'
import { FaucetIcon, MosaicTextIcon } from './Icons.tsx'
import { Menu } from './Menu.tsx'
import { MODAL_LIST } from './modals/constant.ts'
import { Body3 } from './Typography.tsx'

function ButtonConnectWallet() {
  const { onOpenModal } = useModal()

  const { connected, account, name } = useMovementWallet()

  const onPress = () => {
    if (connected) {
      onOpenModal(MODAL_LIST.ACTIVITIES)
    } else {
      onOpenModal(MODAL_LIST.CONNECT_WALLET)
    }
  }

  return (
    <ButtonBase
      v="primary"
      className={
        'flex h-[40px] min-h-[40px] min-w-[140px] items-center gap-2 rounded-[8px] p-0' +
        ' ' +
        (connected
          ? 'border-1 border-borderGrey bg-transparent data-[hover]:bg-transparent'
          : 'border-1 border-primary bg-primary')
      }
      onPress={onPress}
    >
      {connected && name ? <Image src={getWalletImagePath(name)} width={18} /> : null}
      <Body3 className={connected ? 'text-baseGrey' : 'text-baseBlack'}>
        {account ? `${account.address.slice(0, 6)}...${account.address.slice(-4)}` : 'Connect Wallet'}
      </Body3>
    </ButtonBase>
  )
}

export default function Header() {
  const isMd = useIsMd()
  const goHome = () => {
    window.location.href = '/'
  }

  return (
    <>
      <header className="z-[1] mx-auto mt-[20px] flex w-full max-w-[1140px] items-center justify-between px-8 md:px-4">
        {isMd ? (
          <>
            <div className="flex items-center gap-1">
              <Button
                isIconOnly
                variant="light"
                className="h-fit w-fit rounded-none data-[hover]:bg-transparent"
                disableRipple
                disableAnimation
                onPress={goHome}
              >
                <MosaicTextIcon size={28} className="min-h-[28px]" />
              </Button>
            </div>
            <Menu />
            <ButtonConnectWallet />
          </>
        ) : (
          <>
            <div className="flex gap-4">
              <Button
                variant="light"
                className="h-fit min-h-fit w-fit min-w-fit justify-start rounded-none data-[hover]:bg-transparent"
                disableRipple
                isIconOnly
                disableAnimation
                onPress={goHome}
              >
                <MosaicTextIcon size={36} />
              </Button>
              <Button
                className="h-fit gap-1 py-2 text-baseGrey"
                variant="light"
                radius={'sm'}
                startContent={<FaucetIcon size={20} />}
                onPress={() => window.open('https://mizu.testnet.porto.movementnetwork.xyz/')}
              >
                Faucet
              </Button>
            </div>
            <Menu />
            <div className="flex items-center gap-2">
              <ButtonBase
                isDisabled
                v="primary"
                style={{
                  opacity: 1,
                }}
                className={
                  'flex h-[40px] min-h-[40px] min-w-[140px] items-center gap-2 rounded-[8px] p-0' +
                  ' border-1 border-borderGrey bg-transparent hover:cursor-default data-[hover]:bg-transparent'
                }
              >
                <Image
                  src="https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/MOVE.png"
                  width={18}
                />
                <Body3 className={'text-baseGrey'}>Porto testnet</Body3>
              </ButtonBase>
              <ButtonConnectWallet />
            </div>
          </>
        )}
      </header>
    </>
  )
}
