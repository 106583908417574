import { router } from '@/App'
import { AGGREGATOR_API_KEY, AGGREGATOR_URL } from '@/constants'
import axios from 'axios'

const apiClient = axios.create({
  baseURL: AGGREGATOR_URL, // Replace with your API's base URL
})

apiClient.interceptors.request.use(
  (config) => {
    const { search } = router.state.location
    const apiKey = new URLSearchParams(search).get('apiKey')
    config.headers['X-API-KEY'] = apiKey ? apiKey : AGGREGATOR_API_KEY
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default apiClient
