import { Asset } from '@/constants/asset.ts'
import APP_PATHS from '@/constants/path.ts'
import useFullTokens from '@/hooks/useFullTokens.ts'
import SwapWidget, { getIdFromUrl, getSlug } from '@/pages/swap/SwapWidget.tsx'
import { useAppDispatch, useAppSelector } from '@/redux/hooks/index.ts'
import { addTokensToFollow } from '@/redux/slices/token.ts'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useMovementWallet from '../../hooks/useMovementWallet.ts'

const useFindTokenFromUrl = () => {
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const location = useLocation()

  const { data: fullTokenData } = useFullTokens()
  const findTokensFromUrl = useCallback(() => {
    const followingTokenDataList = Object.values(followingTokenData)
    const fullTokenDataList = Object.values(fullTokenData || {})
    const { tokenInSymbolOrAddress, tokenOutSymbolOrAddress } = getIdFromUrl(location.pathname)
    if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress || !fullTokenDataList.length)
      return { newTokenIn: undefined, newTokenOut: undefined }

    const findFn = (symbolOrAddress: string) =>
      followingTokenDataList.find((token) => token?.id === symbolOrAddress) ||
      fullTokenDataList.find((token) => token?.id === symbolOrAddress) ||
      followingTokenDataList.find((token) => token?.coinType === symbolOrAddress) ||
      fullTokenDataList.find((token) => token?.coinType === symbolOrAddress) ||
      followingTokenDataList.find((token) => token?.symbol === symbolOrAddress) ||
      fullTokenDataList.find((token) => token?.symbol === symbolOrAddress)

    const newTokenIn = findFn(tokenInSymbolOrAddress)
    const newTokenOut = findFn(tokenOutSymbolOrAddress)
    return { newTokenIn, newTokenOut }
  }, [followingTokenData, fullTokenData, location.pathname])

  return findTokensFromUrl
}

export default function SwapPage() {
  const wallet = useMovementWallet()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const location = useLocation()
  const { data: fullTokens } = useFullTokens()

  const [tokenIn, setTokenIn] = useState<Asset>()
  const [tokenOut, setTokenOut] = useState<Asset>()

  const syncPairToUrl = useCallback(
    (symbolOrAddressIn: string, symbolOrAddressInOut: string) => {
      const { tokenInSymbolOrAddress, tokenOutSymbolOrAddress } = getIdFromUrl(location.pathname)
      const tokenIn = symbolOrAddressIn || tokenInSymbolOrAddress
      const tokenOut = symbolOrAddressInOut || tokenOutSymbolOrAddress

      if (!tokenIn || !tokenOut) {
        navigate(`${APP_PATHS.SWAP}/MOVE-USDC?${params.toString()}`, { replace: true })
        return
      }
      navigate(`${APP_PATHS.SWAP}/${tokenIn}-${tokenOut}?${params.toString()}`, { replace: true })
    },
    [navigate, params, location.pathname],
  )
  const onChangeTokenIn = useCallback(
    (token: Asset) => {
      syncPairToUrl(getSlug(token), '')
      setTokenIn(token)
    },
    [syncPairToUrl],
  )
  const onChangeTokenOut = useCallback(
    (token: Asset) => {
      syncPairToUrl('', getSlug(token))
      setTokenOut(token)
    },
    [syncPairToUrl],
  )
  const onSwitchToken = useCallback(
    (newTokenIn: Asset, newTokenOut: Asset) => {
      syncPairToUrl(getSlug(newTokenIn), getSlug(newTokenOut))
      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)
    },
    [syncPairToUrl],
  )

  const init = useRef(false)
  const findTokensFromUrl = useFindTokenFromUrl()
  useEffect(() => {
    try {
      if (!Object.values(fullTokens ?? {}).length || init.current) return
      const { newTokenIn, newTokenOut } = findTokensFromUrl()
      if (!newTokenIn) throw new Error(`cannot find tokenIn  `)
      if (!newTokenOut) throw new Error(`cannot find tokenOut `)
      dispatch(addTokensToFollow([newTokenIn.id, newTokenOut.id]))
      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)
      init.current = true
    } catch (err) {
      navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [dispatch, findTokensFromUrl, navigate, params, fullTokens])

  return (
    <div className="z-[1] w-full p-4 pt-[40px]">
      <SwapWidget
        {...{
          wallet,
          onChangeTokenIn,
          onChangeTokenOut,
          onSwitchToken,
          tokenIn,
          tokenOut,
          useLocalState: false,
          defaultInputAmount: params.get('amount') || '1',
        }}
      />
    </div>
  )
}
