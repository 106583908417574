import { Icon } from '@iconify/react'
import { Button } from '@nextui-org/react'
import { ChangeEventHandler } from 'react'
import { NumericFormat } from 'react-number-format'

import { numberWithCommas } from '@/utils/number.ts'

import { Body0, Body4 } from '@/components/Typography.tsx'

import { Asset } from '@/constants/asset.ts'

import { TokenImage } from '@/components/TokenImage'
import { Fraction } from '@/utils/fraction.ts'
export const SelectTokenInput = ({
  label,
  connected,
  onClickToken,
  onChangeInput,
  tokenInInfo,
  onMax,
  balance,
  inputValue,
  disabled,
  amountUsd,
}: {
  connected: boolean
  label: string
  onChangeInput?: ChangeEventHandler<HTMLInputElement> | undefined
  onClickToken: () => void
  onMax?: () => void
  amountUsd: Fraction | undefined
  balance: Fraction | undefined
  tokenInInfo: Asset | undefined
  inputValue: string
  disabled?: boolean
}) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
      <div className="flex items-center justify-between">
        <Body4 className="text-baseGrey">{label}</Body4>
        {connected && (
          <Button
            className="flex h-fit w-fit min-w-fit items-center gap-1 bg-transparent p-0"
            disableAnimation
            disableRipple
            onClick={onMax}
          >
            <Icon icon="mdi:wallet" color="#8B8D91" fontSize={14.08} />
            <Body4 className="text-baseGrey">{balance ? numberWithCommas(balance.toSignificant(6)) : '0'}</Body4>
          </Button>
        )}
      </div>
      <div className="flex items-center justify-between gap-3">
        <NumericFormat
          decimalSeparator="."
          allowedDecimalSeparators={[',']}
          thousandSeparator
          inputMode="decimal"
          autoComplete="off"
          autoCorrect="off"
          type="text"
          placeholder="0.00"
          minLength={1}
          maxLength={30}
          spellCheck="false"
          className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey sm:text-[30px]"
          disabled={disabled}
          pattern="^[0-9]*[.,]?[0-9]*$"
          value={inputValue}
          allowNegative={false}
          onChange={onChangeInput}
        />
        <Button
          className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey bg-background p-0 transition"
          disableAnimation
          disableRipple
          onPress={onClickToken}
        >
          <div className="ml-1.5">
            <TokenImage width={20} height={20} className="min-h-[20px] min-w-[20px]" logoUrl={tokenInInfo?.logoUrl} />
          </div>
          <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">{tokenInInfo?.symbol ?? '--'}</Body0>
          <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
        </Button>
      </div>
      <div className="flex items-center justify-between gap-3">
        <Body4 className="text-baseGrey">
          {amountUsd ? '~$' + numberWithCommas(amountUsd.toSignificant(6), false, 2) : '--'}
        </Body4>
      </div>
    </div>
  )
}
